import * as React from 'react'
import WpAcfHeaderprimaryBlock from '@modules/WpAcfHeaderprimaryBlock'
import { useStaticQuery, graphql } from 'gatsby'

// markup
const NotFoundPage = () => {
	const data = useStaticQuery(graphql`
		{
			wp {
				prototyprOptions {
					optionsIntroHeader {
						backgroundImage {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
							altText
						}
					}
				}
			}
		}
	`)
	return (
		<>
			<WpAcfHeaderprimaryBlock
				data={{ modulesHeaderPrimary: { image: data.wp.prototyprOptions.optionsIntroHeader.backgroundImage, headline: 'Page not found', button: { url: '/', title: 'Zur Home' } } }}
				locale={'de'}
				overline={'404'}
			/>
			{process.env.NODE_ENV === 'development' ? (
				<>
					<br />
					Try creating a page in <code>src/pages/</code>.
					<br />
				</>
			) : null}
		</>
	)
}

export default NotFoundPage
